* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}


a {
    text-decoration: none;
}

body {
    margin: 0;
    gap: 0 0;
}

li {
    list-style: none;
}

h1 {
    padding: 2rem;
}


h4 {
    padding: 1rem;
}

.bg-overlay{
    padding-top: 10rem;
	width: 100%;
    height: 100%;
    min-height: 100vh;

    background-image: linear-gradient(0deg,  rgba(214, 245, 181, 0.69), rgb(255, 255, 255)), url("images/achtergrond.JPG");
    background-size: 100% auto;
    background-repeat: repeat-y;
    margin: 0;

}

.container {
    text-align: center;
}

.welcomeText {
    font-size: 1.2rem;
    margin-top: 5%;
}

.imageButton {
    border: 0.1rem solid black;
    margin: 2rem;
    font-size: 1.15rem;
    height: 15rem;
    width: 15rem;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    position:relative;
}

.img-wrapper{
    position:absolute;
}

.imageButton::after {
    content: '';
    pointer-events: none;
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.5),rgba(255, 255, 255, 0.5));
}

img.hover-zoom {
    position: relative;
    margin-left: -25%;
    margin-top: -52%;
    max-width: 160%;
    max-height: 150%;
    transition: transform .5s ease;
}

img.hover-zoom:hover {
    transform: scale(1.25);
}

#imageText{
    font-size: 1.8rem;
    color: #B51129;
    text-shadow: 10px white;
    margin-top: -60%;
    position: relative;
    text-align: center;
    vertical-align: top;
    pointer-events: none;
    overflow: visible;
    z-index: 1;
    text-shadow: 2px 2px 2px black;

}

.sliderContainer{
    width: 100%;
    height: 100%;
}


.sContainerContainer {
    padding: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}


.slideImage {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0.2rem solid black;
}

.footer{
    margin: 0;
    color: white;
    display: flex;
    justify-content: center;
    padding: 1rem;
    background-color: rgb(61, 61, 61);
    width: 100%;
    left:0;
    bottom: 0;

  }

.twoCtable {
    max-width: 40rem;
}

.threeCtable {
    max-width: 60rem;
}

.prijsCell {
    min-width: 6rem;
}

.tarievenTablesDiv {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.tarievenTablesOpmaak {
    background-color: rgb(255, 255, 255);
    
}

.toeristenTableDiv{
    margin: 1rem;
}

.huisjesTableDiv {
    margin: 1rem;
    min-width: 20rem;
    max-width: 40rem;
}

#huisjesTarievenText{
    margin: 1rem;
}

#tarievenNieuwButton {
    background-color: #53361B;
    font-size: 1.5rem;
    border: none;
    color: white;
    padding: 2rem;
    margin: 2rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

#tarievenNieuwButton:hover {
    background-color: rgb(145, 94, 47);
    transition: 0.3s ease;
}

#recronLogo {
    width: 12rem;
}

.pageContentDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.introText {
    width: 80rem;
}

.zijText{
    width: 40rem;
}

.zijfotosDiv {
    width: 30rem;
}

.onderfotosDiv {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80rem;
}


.zijfoto{
    width: 90%;
    margin: 1rem;
    height: auto;
    border: 0.1rem solid black;
}

.onderfoto{
    width: 40%;
    min-width: none;
    margin: 2.5%;
    height: auto;
    border: 0.1rem solid black;
}

#molenLinksDiv {
    margin-top: 1rem;
}

.molenButtons {
    background-color: #53361B;
    width: 100%;
    font-size: 1.3rem;
    border: none;
    color: white;
    margin: .3rem;
    padding: .3rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    transition-duration: 0.4s;
}
.molenButtons:hover {
    background-color: rgb(145, 94, 47);
    transform: .3s ease;
}

#plattegrond {
    width: 100%;
    margin: 2rem;
}

#mapsContainer{
    width: 50rem;
    height: 25rem;
    margin: 2rem;
    
}

#googleMaps {
    width: 100%;
    height: 100%;
    border: 0.1rem solid black;
}

.logoDiv{
    height: auto;
    width: 10rem;
    position: relative;
}

.navbarLogo {
    height: 100%;
    width: 100%;
}


/* NAVBAR STYLING STARTS */
.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(83, 54, 27);
    color: #fff;
    height: 7rem;
    box-shadow: 1px 4px 12px rgba(0, 0, 0, 0.4);
   }
   .navbar li.active {
    background-color: rgb(37, 25, 13);
    }
   .nav-links{
    padding: 1rem;
    height: 100%;
    
   }
   .nav-links a {
    color: #fff;
    text-decoration: none;

   }
   /* NAVBAR MENU */
   .menu {
    display: flex;
    gap: 1em;
    font-size: 18px;
   }
   .menu li:hover {
    background-color: rgb(145, 94, 47);
    transition: 0.3s ease;
    color: #fff;
   }
   .menu li {
    padding: 1rem;
   }


/*RESPONSIVE NAVBAR MENU STARTS*/
/* CHECKBOX HACK */
input[type=checkbox]{
    display: none;
    
   } 
   /*HAMBURGER MENU*/
   .hamburger {
    display: none;
    font-size: 48px;
    user-select: none;
   }
   /* APPLYING MEDIA QUERIES */
   @media (max-width: 80rem) {
   .menu { 
    display:none;
    position: absolute;
    background-color:rgb(83, 54, 27);
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
   }
   .menu li + li {
    margin-top: 12px;
   }
   input[type=checkbox]:checked ~ .menu{
    display: block;
   }
   .hamburger {
    display: block;
   }
   }

   #fixedNav {
    width: 100%;
    z-index: 99;
    position: fixed;
   }